import { omit } from 'lodash';
import { SET_OUTGO_TYPES, ADD_OUTGO_TYPE, DELETE_OUTGO_TYPE, EDIT_OUTGO_TYPE } from 'core/actions/outgoTypes';
import { SET_INIT_DATA } from 'core/actions/init';


 const outgoTypes = ( state = {}, action ) => {
     switch(action.type) {
         
         case SET_OUTGO_TYPES: 
         case SET_INIT_DATA: return { 
                 ...state,
                 ...action.data.entities.outgoTypes,
                };
                
        case ADD_OUTGO_TYPE: return {
                ...state,
                [action.data.result]: {
                    ...action.data.entities.outgoTypes[action.data.result],
                }
            };
        
        case EDIT_OUTGO_TYPE: return {
                ...state,
                [action.data.result]: {
                    ...state[action.data.result],
                    ...action.data.entities.outgoTypes[action.data.result],
                }
            };

        case DELETE_OUTGO_TYPE: return {
            ...omit(state, action.id)
        };

        default:
            return state;
     }
 };

 export default outgoTypes;