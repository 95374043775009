import { omit } from "lodash";
import {
  API_REQUEST,
  API_SUCCESS,
  API_FAILURE,
  API_END,
} from "core/actions/api";

/**
 * App receives errors as:
 * { fieldNameX: ['err1', 'err2', ...], fieldNameY: ['err1', 'err2', ...], ... }
 * Every field can have one or more error
 *
 * Example:
 * --------
 * {
 *     "#": [], // errors related to form if its exists
 *     "fieldName1": ['error 1', 'error 2'], //Every field can have one or more error
 *     "fieldName2": ['error 1'],
 *     "fieldName3": ['error 1', 'error 2', 'error 3'],
 * }
 */

const api = (state = {}, action) => {
  switch (action.type) {
    case API_REQUEST:
      return {
        ...state,
        [action.key]: {
          isFetching: true,
          finished: false,
          data: null,
          success: false,
          error: "",
          validationErrors: {},
        },
      };

    case API_SUCCESS:
      return {
        ...state,
        [action.key]: {
          isFetching: false,
          finished: true,
          success: true,
          data: action.data, // response data
          error: "",
          validationErrors: {},
        },
      };

    case API_FAILURE: {
      // console.log('API Original Error', action.error);
      let { response } = action.error;
      // default error message
      let error = "Désolé, l'application ne peut pas traiter votre action!";
      // form validation errors (by field name)
      let validationErrors = {};

      if (response) {
        if (response.status === 422) {
          // Form Validation Errors
          error = "Données invalides";
          const errors = response.data;
          for (const fieldName in errors) {
            if (errors.hasOwnProperty(fieldName)) {
              // combine array of errors to one error string
              validationErrors[fieldName] = errors[fieldName].join(", ");
            }
          }
        } else if (response.status === 401) {
          // Authorozition / login required
          error = response.data.error;
        } else if (response.status === 403) {
          // permission to a task is needed
          error = "Cette opération n'est pas autorisée pour ce compte";
        }
      }

      return {
        ...state,
        [action.key]: {
          isFetching: false,
          finished: true,
          data: null,
          success: false,
          error,
          validationErrors,
        },
      };
    }

    case API_END:
      return {
        ...omit(state, action.key),
      };

    /* case API_CLEAR: return {
            ...state,
            [action.key]: {
                isFetching: false,
                data: {}, 
                success: false,
                failure: false,
                errors: {},
            }
        }; */

    default:
      return state;
  }
};

export default api;
