import * as schemas from 'core/schemas';
import { apiActionCreator } from './api';

export const SET_OUTGOINGS = 'SET_OUTGOINGS';
export const setOutgoings = data => ({
    type: SET_OUTGOINGS,
    data,
});
export const ADD_OUTGO = 'ADD_OUTGO';
export const addOutgo = data => ({
    type: ADD_OUTGO,
    data,
});
export const EDIT_OUTGO = 'EDIT_OUTGO';
export const editOutgo = data => ({
    type: EDIT_OUTGO,
    data,
});
export const DELETE_OUTGO = 'DELETE_OUTGO';
export const deleteOutgo = id => ({
    type: DELETE_OUTGO,
    id,
});



// API ACTIONS
export const fetchOutgoings = callkey => apiActionCreator({
    callKey: callkey,
    endpoint: `/outgoings`,
    method: 'GET',
    schema: schemas.outgoingsList,
    onSuccess: setOutgoings,
});

export const createOutgo = (callkey, outgo) => apiActionCreator({
    callKey: callkey,
    endpoint: `/outgoings`,
    method: 'POST',
    data: outgo,
    schema: schemas.outgo,
    onSuccess: addOutgo,
});

export const updateOutgo = (callkey, id, outgo) => apiActionCreator({
    callKey: callkey,
    endpoint: `/outgoings/${id}`,
    method: 'PUT',
    data: outgo,
    schema: schemas.outgo,
    onSuccess: editOutgo,
});


export const removeOutgo = (callkey, id) => apiActionCreator({
    callKey: callkey,
    endpoint:`/outgoings/${id}`,
    method: 'DELETE',
    onSuccess: () => deleteOutgo(id),
});