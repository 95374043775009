import * as schemas from 'core/schemas';
import { apiActionCreator } from './api';

export const SET_INCOME_TYPES = 'SET_INCOME_TYPES';
export const setIncomeTypes = data => ({
    type: SET_INCOME_TYPES,
    data,
});
export const ADD_INCOME_TYPE = 'ADD_INCOME_TYPE';
export const addIncomeType = data => ({
    type: ADD_INCOME_TYPE,
    data,
});
export const EDIT_INCOME_TYPE = 'EDIT_INCOME_TYPE';
export const editIncomeType = data => ({
    type: EDIT_INCOME_TYPE,
    data,
});
export const DELETE_INCOME_TYPE = 'DELETE_INCOME_TYPE';
export const deleteIncomeType = id => ({
    type: DELETE_INCOME_TYPE,
    id,
});



// API ACTIONS
export const fetchIncomeTypes = callkey => apiActionCreator({
    callKey: callkey,
    endpoint: `/income_types`,
    method: 'GET',
    schema: schemas.incomeTypesList,
    onSuccess: setIncomeTypes,
});

export const createIncomeType = (callkey, incomeType) => apiActionCreator({
    callKey: callkey,
    endpoint: `/income_types`,
    method: 'POST',
    data: incomeType,
    schema: schemas.incomeType,
    onSuccess: addIncomeType,
});

export const updateIncomeType = (callkey, id, incomeType) => apiActionCreator({
    callKey: callkey,
    endpoint: `/income_types/${id}`,
    method: 'PUT',
    data: incomeType,
    schema: schemas.incomeType,
    onSuccess: editIncomeType,
});


export const removeIncomeType = (callkey, id) => apiActionCreator({
    callKey: callkey,
    endpoint:`/income_types/${id}`,
    method: 'DELETE',
    onSuccess: () => deleteIncomeType(id),
});