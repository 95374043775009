import * as schemas from 'core/schemas';
import { apiActionCreator } from './api';

export const SET_OUTGO_TYPES = 'SET_OUTGO_TYPES';
export const setOutgoTypes = data => ({
    type: SET_OUTGO_TYPES,
    data,
});
export const ADD_OUTGO_TYPE = 'ADD_OUTGO_TYPE';
export const addOutgoType = data => ({
    type: ADD_OUTGO_TYPE,
    data,
});
export const EDIT_OUTGO_TYPE = 'EDIT_OUTGO_TYPE';
export const editOutgoType = data => ({
    type: EDIT_OUTGO_TYPE,
    data,
});
export const DELETE_OUTGO_TYPE = 'DELETE_OUTGO_TYPE';
export const deleteOutgoType = id => ({
    type: DELETE_OUTGO_TYPE,
    id,
});



// API ACTIONS
export const fetchOutgoTypes = callkey => apiActionCreator({
    callKey: callkey,
    endpoint: `/outgo_types`,
    method: 'GET',
    schema: schemas.outgoTypesList,
    onSuccess: setOutgoTypes,
});

export const createOutgoType = (callkey, outgoType) => apiActionCreator({
    callKey: callkey,
    endpoint: `/outgo_types`,
    method: 'POST',
    data: outgoType,
    schema: schemas.outgoType,
    onSuccess: addOutgoType,
});

export const updateOutgoType = (callkey, id, outgoType) => apiActionCreator({
    callKey: callkey,
    endpoint: `/outgo_types/${id}`,
    method: 'PUT',
    data: outgoType,
    schema: schemas.outgoType,
    onSuccess: editOutgoType,
});


export const removeOutgoType = (callkey, id) => apiActionCreator({
    callKey: callkey,
    endpoint:`/outgo_types/${id}`,
    method: 'DELETE',
    onSuccess: () => deleteOutgoType(id),
});