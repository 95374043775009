import * as schemas from "core/schemas";
import { apiActionCreator } from "./api";

export const SET_SUB_OUTGO_TYPES = "SET_SUB_OUTGO_TYPES";
export const setSubOutgoTypes = (data) => ({
  type: SET_SUB_OUTGO_TYPES,
  data,
});
export const ADD_SUB_OUTGO_TYPE = "ADD_SUB_OUTGO_TYPE";
export const addSubOutgoType = (data) => ({
  type: ADD_SUB_OUTGO_TYPE,
  data,
});
export const EDIT_SUB_OUTGO_TYPE = "EDIT_SUB_OUTGO_TYPE";
export const editSubOutgoType = (data) => ({
  type: EDIT_SUB_OUTGO_TYPE,
  data,
});
export const DELETE_SUB_OUTGO_TYPE = "DELETE_SUB_OUTGO_TYPE";
export const deleteSubOutgoType = (id) => ({
  type: DELETE_SUB_OUTGO_TYPE,
  id,
});

// API ACTIONS
export const fetchSubOutgoTypes = (callkey) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/sub_outgo_types`,
    method: "GET",
    schema: schemas.subOutgoTypesList,
    onSuccess: setSubOutgoTypes,
  });

export const createSubOutgoType = (callkey, subOutgoType) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/sub_outgo_types`,
    method: "POST",
    data: subOutgoType,
    schema: schemas.subOutgoType,
    onSuccess: addSubOutgoType,
  });

export const updateSubOutgoType = (callkey, id, subOutgoType) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/sub_outgo_types/${id}`,
    method: "PUT",
    data: subOutgoType,
    schema: schemas.subOutgoType,
    onSuccess: editSubOutgoType,
  });

export const removeSubOutgoType = (callkey, id) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/sub_outgo_types/${id}`,
    method: "DELETE",
    onSuccess: () => deleteSubOutgoType(id),
  });
