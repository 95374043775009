import * as schemas from 'core/schemas';
import { apiActionCreator } from './api';

export const SET_INIT_DATA = 'SET_INIT_DATA';
export const setInitData = data => ({
    type: SET_INIT_DATA,
    data,
});


// API ACTIONS
export const fetchInitData = callkey => apiActionCreator({
    callKey: callkey,
    endpoint: `/init`,
    method: 'GET',
    schema: schemas.initData,
    onSuccess: setInitData,
});