import { LOGIN, LOGOUT } from "core/actions/auth";

// Get user data from localStorage or somewhere else. and init state
const { id = null, username = "", roles = [] } =
  JSON.parse(localStorage.getItem("incomes_app_user")) || {};

const loggedIn = id && username !== "" && roles.length > 0;

const initState = {
  loggedIn: loggedIn,
  user: loggedIn ? { id, username, roles } : {},
};

const auth = (state = initState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        loggedIn: true,
        user: {
          id: action.data.id,
          username: action.data.username,
          roles: action.data.roles,
        },
      };

    case LOGOUT:
      return {
        loggedIn: false,
        user: {},
      };

    default:
      return state;
  }
};

export default auth;
