import { omit, merge } from "lodash";
import {
  SET_BOXDAYS,
  ADD_BOXDAY,
  DELETE_BOXDAY,
  EDIT_BOXDAY,
} from "core/actions/boxdays";
import { SET_INIT_DATA } from "core/actions/init";
import { ADD_INCOME, DELETE_INCOME } from "core/actions/incomes";
import { ADD_OUTGO, DELETE_OUTGO } from "core/actions/outgoings";

const addIncome = (state, action) => {
  const { entities, result: incomeId } = action.data;
  const income = entities.incomes[incomeId];
  return {
    ...state,
    [income.box]: {
      ...state[income.box],
      incomes: state[income.box].incomes.concat(incomeId),
    },
  };
};

const addOutgo = (state, action) => {
  const { entities, result: outgoId } = action.data;
  const outgo = entities.outgoings[outgoId];
  return {
    ...state,
    [outgo.box]: {
      ...state[outgo.box],
      outgoings: state[outgo.box].outgoings.concat(outgoId),
    },
  };
};

const deleteIncome = (state, action) => {
  const incomeId = action.id;
  for (const key in state) {
    const { incomes = [] } = state[key];
    if (incomes.includes(incomeId)) {
      return {
        ...state,
        [key]: {
          ...state[key],
          incomes: incomes.filter((id) => id !== incomeId),
        },
      };
    }
  }

  return state;
};

const deleteOutgo = (state, action) => {
  const outgoId = action.id;
  for (const key in state) {
    const { outgoings = [] } = state[key];
    if (outgoings.includes(outgoId)) {
      return {
        ...state,
        [key]: {
          ...state[key],
          outgoings: outgoings.filter((id) => id !== outgoId),
        },
      };
    }
  }
  return state;
};

const boxdays = (state = {}, action) => {
  switch (action.type) {
    case SET_BOXDAYS:
    case SET_INIT_DATA:
      return merge({}, state, action.data.entities.boxdays);

    case ADD_BOXDAY:
      return {
        ...state,
        [action.data.result]: {
          ...action.data.entities.boxdays[action.data.result],
        },
      };

    case EDIT_BOXDAY:
      return {
        ...state,
        [action.data.result]: {
          ...state[action.data.result],
          ...action.data.entities.boxdays[action.data.result],
        },
      };

    case DELETE_BOXDAY:
      return {
        ...omit(state, action.id),
      };

    // on income add edit boxday.incomes array
    case ADD_INCOME:
      return addIncome(state, action);

    // on income add edit boxday.outgoings array
    case ADD_OUTGO:
      return addOutgo(state, action);

    // on delete income update boxday.incomes array
    case DELETE_INCOME:
      return deleteIncome(state, action);

    // on delete outgo update boxday.outgoings array
    case DELETE_OUTGO:
      return deleteOutgo(state, action);

    default:
      return state;
  }
};

export default boxdays;
