import { createStore, applyMiddleware, compose } from 'redux';
import { apiMiddleware, authMiddleware } from 'core/middlewares';
import reducer from 'core/reducers';

// http://extension.remotedev.io/#12-advanced-store-setup
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(preloadedState) {
    return createStore(
        reducer, 
        preloadedState, 
        composeEnhancers(
            applyMiddleware(
                apiMiddleware,
                authMiddleware,
            )
        )
    );
};

