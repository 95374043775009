import { omit } from 'lodash';
import { SET_INIT_DATA } from 'core/actions/init';
import { SET_USERS, ADD_USER, DELETE_USER, EDIT_USER } from 'core/actions/users';


 const users = ( state = {}, action ) => {
     switch(action.type) {
         
         case SET_USERS: 
         case SET_INIT_DATA: return { 
                 ...state,
                 ...action.data.entities.users,
                };
                
        case ADD_USER: return {
                ...state,
                [action.data.result]: {
                    ...action.data.entities.users[action.data.result],
                }
            };
        
        case EDIT_USER: return {
                ...state,
                [action.data.result]: {
                    ...state[action.data.result],
                    ...action.data.entities.users[action.data.result],
                }
            };

        case DELETE_USER: return {
            ...omit(state, action.id)
        };

        default:
            return state;
     }
 };

 export default users;