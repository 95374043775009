import axios from "axios";
import { schema as normalizrSchema, normalize } from "normalizr";
import {
  API_ACTION_KEY,
  apiRequest,
  apiSuccess,
  apiFailure,
} from "./../actions/api";

const apiMiddleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    // get action object
    const apiAction = action[API_ACTION_KEY];
    if (typeof apiAction === "undefined") {
      return next(action);
    }

    // extract action payload
    // const { loggedIn = false } = getState().auth;
    const {
      callKey,
      data,
      params,
      method,
      endpoint,
      schema,
      onSuccess,
    } = apiAction;
    const rSchema = Array.isArray(schema) ? schema[0] : schema;
    // const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";

    if (getState().api[callKey] && getState().api[callKey].isFetching) {
      return;
    }

    if (typeof endpoint !== "string") {
      throw new Error("Expected string as endpoint.");
    }

    if (schema && !(rSchema instanceof normalizrSchema.Entity)) {
      throw new Error("Expected schema to be instance of schema.Entity.");
    }

    // dispatch request
    dispatch(apiRequest(callKey));

    return axios({
      url: `${process.env.REACT_APP_API_URL}${endpoint}`,
      method,
      data,
      params,
      withCredentials: true, // https://codewithhugo.com/pass-cookies-axios-fetch-requests/
      // crossDomain: true,
    })
      .then((response) => {
        const resData = !!schema ? normalize(response.data, schema) : response.data;
        // console.log("Response", endpoint, resData);
        dispatch(apiSuccess(callKey, response.data));
        if (typeof onSuccess === "function") {
          dispatch(onSuccess(resData));
        }
      })
      .catch((error) => {
        dispatch(apiFailure(callKey, error));
      });
  };
};

export default apiMiddleware;
