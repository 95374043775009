import React from 'react';
import { Box, Typography, CircularProgress } from '@material-ui/core';

const AppLoader = ({ height = "95vh", message = 'Chargement des données ...' }) => {
    return (
        <Box height={height} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography component="p" variant="button" color="primary" style={{ marginBottom: 15 }}>
                { message }
            </Typography>
            <CircularProgress size={32} color="secondary" thickness={2} />
        </Box>
    );
};

export default AppLoader;