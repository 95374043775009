import { omit, merge } from 'lodash';
import { SET_INCOMES, ADD_INCOME, DELETE_INCOME, EDIT_INCOME } from 'core/actions/incomes';
import { SET_INIT_DATA } from 'core/actions/init';
import { SET_BOXDAYS } from 'core/actions/boxdays';


 const incomes = ( state = {}, action ) => {
     switch(action.type) {
         
         case SET_INCOMES: 
         case SET_INIT_DATA: 
         case SET_BOXDAYS: 
            return merge({}, state, action.data.entities.incomes);
                
        case ADD_INCOME: return {
            ...state,
            [action.data.result]: {
                ...action.data.entities.incomes[action.data.result],
            }
        };
        
        case EDIT_INCOME: return {
            ...state,
            [action.data.result]: {
                ...state[action.data.result],
                ...action.data.entities.incomes[action.data.result],
            }
        };

        case DELETE_INCOME: return {
            ...omit(state, action.id)
        };

        default:
            return state;
     }
 };

 export default incomes;