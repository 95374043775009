import { combineReducers } from 'redux';
import api from './api';
import auth from './auth';
import boxdays from './boxdays';
import users from './users';
import incomes from './incomes';
import outgoings from './outgoings';
import incomeTypes from './incomeTypes';
import subIncomeTypes from './subIncomeTypes';
import outgoTypes from './outgoTypes';
import subOutgoTypes from './subOutgoTypes';

const entities = combineReducers({
    users,
    incomeTypes,
    subIncomeTypes,
    outgoTypes,
    subOutgoTypes,
    incomes,
    outgoings,
    boxdays,
});

const reducer = combineReducers({
    api,
    auth,
    entities,
});

export default reducer;