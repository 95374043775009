import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppLoader from "components/AppLoader";

// lazy loading
const AuthenticatedApp = lazy(() => import("./AuthenticatedApp"));
const UnauthenticatedApp = lazy(() => import("./UnauthenticatedApp"));

const App = () => {
  const auth = useSelector((state) => state.auth);

  return (
    <Suspense fallback={<AppLoader height="96vh" />}>
      <CssBaseline />
      {auth.loggedIn === true ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </Suspense>
  );
};

export default App;
