import { omit } from "lodash";
import {
  SET_SUB_INCOME_TYPES,
  ADD_SUB_INCOME_TYPE,
  DELETE_SUB_INCOME_TYPE,
  EDIT_SUB_INCOME_TYPE,
} from "core/actions/subIncomeTypes";
import { SET_INIT_DATA } from "core/actions/init";

const subIncomeTypes = (state = {}, action) => {
  switch (action.type) {
    case SET_SUB_INCOME_TYPES:
    case SET_INIT_DATA:
      return {
        ...state,
        ...action.data.entities.subIncomeTypes,
      };

    case ADD_SUB_INCOME_TYPE:
      return {
        ...state,
        [action.data.result]: {
          ...action.data.entities.subIncomeTypes[action.data.result],
        },
      };

    case EDIT_SUB_INCOME_TYPE:
      return {
        ...state,
        [action.data.result]: {
          ...state[action.data.result],
          ...action.data.entities.subIncomeTypes[action.data.result],
        },
      };

    case DELETE_SUB_INCOME_TYPE:
      return {
        ...omit(state, action.id),
      };

    default:
      return state;
  }
};

export default subIncomeTypes;
