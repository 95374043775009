import { apiActionCreator } from './api';

export const LOGIN = 'LOGIN';
export const login = data => ({
    type: LOGIN,
    data,
});

export const LOGOUT = 'LOGOUT';
export const logout = () => ({
    type: LOGOUT
});

export const postLogin = (callkey, data) => apiActionCreator({
    callKey: callkey,
    endpoint: `/login`,
    method: 'POST',
    data: data,
    onSuccess: login,
});

export const postLogout = callkey => apiActionCreator({
    callKey: callkey,
    endpoint: `/logout`,
    method: 'POST',
    onSuccess: logout,
});