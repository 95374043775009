import { schema } from "normalizr";

// User
export const user = new schema.Entity("users");
export const usersList = [user];

// Box day
export const boxday = new schema.Entity("boxdays");
export const boxdaysList = [boxday];

// IncomeType
export const incomeType = new schema.Entity("incomeTypes");
export const incomeTypesList = [incomeType];

// SubIncomeType
export const subIncomeType = new schema.Entity("subIncomeTypes");
export const subIncomeTypesList = [subIncomeType];

// OutgoType
export const outgoType = new schema.Entity("outgoTypes");
export const outgoTypesList = [outgoType];

// SubOutgoType
export const subOutgoType = new schema.Entity("subOutgoTypes");
export const subOutgoTypesList = [subOutgoType];

// Income
export const income = new schema.Entity("incomes");
export const incomesList = [income];

// Outgo
export const outgo = new schema.Entity("outgoings");
export const outgoingsList = [outgo];

// ** a special schema for the loaded initial data
export const initData = new schema.Entity("init", {
  users: usersList,
  incomeTypes: incomeTypesList,
  subIncomeTypes: subIncomeTypesList,
  outgoTypes: outgoTypesList,
  subOutgoTypes: subOutgoTypesList,
  boxdays: boxdaysList,
});


subIncomeType.define({
  type: incomeType,
});
subOutgoType.define({
  type: outgoType,
});

income.define({
  user: user,
  box: boxday,
  type: incomeType,
  subType: subIncomeType,
});

outgo.define({
  user: user,
  box: boxday,
  type: outgoType,
  subType: subOutgoType,
});

boxday.define({
  incomes: incomesList,
  outgoings: outgoingsList,
});
