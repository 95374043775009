import * as schemas from 'core/schemas';
import { apiActionCreator } from './api';

export const SET_INCOMES = 'SET_INCOMES';
export const setIncomes = data => ({
    type: SET_INCOMES,
    data,
});
export const ADD_INCOME = 'ADD_INCOME';
export const addIncome = data => ({
    type: ADD_INCOME,
    data,
});
export const EDIT_INCOME = 'EDIT_INCOME';
export const editIncome = data => ({
    type: EDIT_INCOME,
    data,
});
export const DELETE_INCOME = 'DELETE_INCOME';
export const deleteIncome = id => ({
    type: DELETE_INCOME,
    id,
});



// API ACTIONS
export const fetchIncomes = callkey => apiActionCreator({
    callKey: callkey,
    endpoint: `/incomes`,
    method: 'GET',
    schema: schemas.incomesList,
    onSuccess: setIncomes,
});

export const createIncome = (callkey, income) => apiActionCreator({
    callKey: callkey,
    endpoint: `/incomes`,
    method: 'POST',
    data: income,
    schema: schemas.income,
    onSuccess: addIncome,
});

export const updateIncome = (callkey, id, income) => apiActionCreator({
    callKey: callkey,
    endpoint: `/incomes/${id}`,
    method: 'PUT',
    data: income,
    schema: schemas.income,
    onSuccess: editIncome,
});


export const removeIncome = (callkey, id) => apiActionCreator({
    callKey: callkey,
    endpoint:`/incomes/${id}`,
    method: 'DELETE',
    onSuccess: () => deleteIncome(id),
});