import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { SnackbarProvider } from "notistack";
import configureStore from "core/store/configureStore";
import App from "app";

const store = configureStore();

const Root = () => {
  return (
    <ReduxProvider store={store}>
      <Router basename={process.env.REACT_APP_PUBLIC_PATH}>
        <SnackbarProvider anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <App />
        </SnackbarProvider>
      </Router>
    </ReduxProvider>
  );
};

export default Root;
