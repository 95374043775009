import { omit } from "lodash";
import {
  SET_SUB_OUTGO_TYPES,
  ADD_SUB_OUTGO_TYPE,
  DELETE_SUB_OUTGO_TYPE,
  EDIT_SUB_OUTGO_TYPE,
} from "core/actions/subOutgoTypes";
import { SET_INIT_DATA } from "core/actions/init";

const subOutgoTypes = (state = {}, action) => {
  switch (action.type) {
    case SET_SUB_OUTGO_TYPES:
    case SET_INIT_DATA:
      return {
        ...state,
        ...action.data.entities.subOutgoTypes,
      };

    case ADD_SUB_OUTGO_TYPE:
      return {
        ...state,
        [action.data.result]: {
          ...action.data.entities.subOutgoTypes[action.data.result],
        },
      };

    case EDIT_SUB_OUTGO_TYPE:
      return {
        ...state,
        [action.data.result]: {
          ...state[action.data.result],
          ...action.data.entities.subOutgoTypes[action.data.result],
        },
      };

    case DELETE_SUB_OUTGO_TYPE:
      return {
        ...omit(state, action.id),
      };

    default:
      return state;
  }
};

export default subOutgoTypes;