import * as schemas from "core/schemas";
import { apiActionCreator } from "./api";

export const SET_BOXDAYS = "SET_BOXDAYS";
export const setBoxdays = (data) => ({
  type: SET_BOXDAYS,
  data,
});
export const ADD_BOXDAY = "ADD_BOXDAY";
export const addBoxday = (data) => ({
  type: ADD_BOXDAY,
  data,
});
export const EDIT_BOXDAY = "EDIT_BOXDAY";
export const editBoxday = (data) => ({
  type: EDIT_BOXDAY,
  data,
});
export const DELETE_BOXDAY = "DELETE_BOXDAY";
export const deleteBoxday = (id) => ({
  type: DELETE_BOXDAY,
  id,
});

// API ACTIONS
export const fetchBoxdays = (callkey, params = {}) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/boxdays`,
    method: "GET",
    params: params,
    schema: schemas.boxdaysList,
    onSuccess: setBoxdays,
  });

export const moveBoxday = (callkey) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/boxdays/move`,
    method: "POST",
    schema: schemas.boxday,
    onSuccess: addBoxday,
  });

export const updateBoxdayDate = (callkey, newData) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/boxdays/update_date`,
    method: "POST",
    data: newData,
    schema: schemas.boxday,
    onSuccess: addBoxday,
  });

export const removeBoxday = (callkey, id) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/boxdays/${id}`,
    method: "DELETE",
    onSuccess: () => deleteBoxday(id),
  });
