import * as schemas from 'core/schemas';
import { apiActionCreator } from './api';

export const SET_USERS = 'SET_USERS';
export const setUsers = data => ({
    type: SET_USERS,
    data,
});
export const ADD_USER = 'ADD_USER';
export const addUser = data => ({
    type: ADD_USER,
    data,
});
export const EDIT_USER = 'EDIT_USER';
export const editUser = data => ({
    type: EDIT_USER,
    data,
});
export const DELETE_USER = 'DELETE_USER';
export const deleteUser = id => ({
    type: DELETE_USER,
    id,
});



// API ACTIONS
export const fetchUsers = callkey => apiActionCreator({
    callKey: callkey,
    endpoint: `/users`,
    method: 'GET',
    schema: schemas.usersList,
    onSuccess: setUsers,
});

export const createUser = (callkey, user) => apiActionCreator({
    callKey: callkey,
    endpoint: `/users`,
    method: 'POST',
    data: user,
    schema: schemas.user,
    onSuccess: addUser,
});

export const updateUser = (callkey, id, user) => apiActionCreator({
    callKey: callkey,
    endpoint: `/users/${id}`,
    method: 'PUT',
    data: user,
    schema: schemas.user,
    onSuccess: addUser,
});

export const updateUserPassword = (callkey, id, data) => apiActionCreator({
    callKey: callkey,
    endpoint: `/users/${id}/password`,
    method: 'PUT',
    data: data,
});

export const updateUsername = (callkey, id, data) => apiActionCreator({
    callKey: callkey,
    endpoint: `/users/${id}/username`,
    method: 'PUT',
    data: data,
    schema: schemas.user,
    onSuccess: editUser,
});

export const updateUserRole = (callkey, id, data) => apiActionCreator({
    callKey: callkey,
    endpoint: `/users/${id}/role`,
    method: 'PUT',
    data: data,
    schema: schemas.user,
    onSuccess: editUser,
});


export const removeUser = (callkey, id) => apiActionCreator({
    callKey: callkey,
    endpoint:`/users/${id}`,
    method: 'DELETE',
    onSuccess: () => deleteUser(id),
});