// import Axios from 'axios';
import { LOGIN, LOGOUT, logout /* postLogout, */ } from "core/actions/auth";
import { API_FAILURE } from "core/actions/api";

const authMiddleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    switch (action.type) {
      case LOGIN:
        {
          // console.log("Middlewares/auth: LOGIN", action.data);

          const { data } = action;
          localStorage.setItem(
            "incomes_app_user",
            JSON.stringify({
              id: data.id,
              username: data.username,
              roles: data.roles,
            })
          );
        }
        break;

      case LOGOUT:
        // console.log("Middlewares/auth: LOGOUT");

        // localStorage.clear();
        localStorage.removeItem("incomes_app_user");

        // remove axios defaults
        // Axios.defaults.withCredentials = false;
        break;

      case API_FAILURE:
        {
          console.log("middlewares/auth: API FAILUER", action.error);
          const { response } = action.error;

          if (response) {
            // Unauthorized: uset not authenticated
            if (response.status === 401) {
              // dispatch(postLogout('postLogoutMiddleware')); // backend logout
              dispatch(logout()); // front end logout
            }
            // Forbidden: user have not permisson to some actions
            /* else if(response.status === 403) {
                        // dispatch(postLogout('postLogoutMiddleware'));
                        dispatch(logout());
                    } */
          }
        }
        break;

      default:
    }

    return next(action);
  };
};

export default authMiddleware;
