/**
 * Data fetching in Redux apps: A 100% correct approach
 * https://blog.logrocket.com/data-fetching-in-redux-apps-a-100-correct-approach-4d26e21750fc/
 */

export const API_ACTION_KEY = "API_ACTION_KEY";

export const API_REQUEST = "API_REQUEST";
export const apiRequest = (key) => ({
  type: API_REQUEST,
  key,
});

export const API_SUCCESS = "API_SUCCESS";
export const apiSuccess = (key, data) => ({
  type: API_SUCCESS,
  key,
  data,
});

export const API_FAILURE = "API_FAILURE";
export const apiFailure = (key, error) => ({
  type: API_FAILURE,
  key,
  error,
});

export const API_END = "API_END";
export const apiEnd = (key) => ({
  type: API_END,
  key,
});

export const apiActionCreator = ({
  callKey = "",
  endpoint = "",
  method = "",
  data = {},
  params = {},
  schema = null,
  onSuccess = null,
}) => ({
  [API_ACTION_KEY]: {
    callKey,
    endpoint,
    method,
    data,
    params,
    schema,
    onSuccess,
  },
});
