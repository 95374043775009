import * as schemas from "core/schemas";
import { apiActionCreator } from "./api";

export const SET_SUB_INCOME_TYPES = "SET_SUB_INCOME_TYPES";
export const setSubIncomeTypes = (data) => ({
  type: SET_SUB_INCOME_TYPES,
  data,
});
export const ADD_SUB_INCOME_TYPE = "ADD_SUB_INCOME_TYPE";
export const addSubIncomeType = (data) => ({
  type: ADD_SUB_INCOME_TYPE,
  data,
});
export const EDIT_SUB_INCOME_TYPE = "EDIT_SUB_INCOME_TYPE";
export const editSubIncomeType = (data) => ({
  type: EDIT_SUB_INCOME_TYPE,
  data,
});
export const DELETE_SUB_INCOME_TYPE = "DELETE_SUB_INCOME_TYPE";
export const deleteSubIncomeType = (id) => ({
  type: DELETE_SUB_INCOME_TYPE,
  id,
});

// API ACTIONS
export const fetchSubIncomeTypes = (callkey) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/sub_income_types`,
    method: "GET",
    schema: schemas.subIncomeTypesList,
    onSuccess: setSubIncomeTypes,
  });

export const createSubIncomeType = (callkey, SubIncomeType) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/sub_income_types`,
    method: "POST",
    data: SubIncomeType,
    schema: schemas.subIncomeType,
    onSuccess: addSubIncomeType,
  });

export const updateSubIncomeType = (callkey, id, SubIncomeType) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/sub_income_types/${id}`,
    method: "PUT",
    data: SubIncomeType,
    schema: schemas.subIncomeType,
    onSuccess: editSubIncomeType,
  });

export const removeSubIncomeType = (callkey, id) =>
  apiActionCreator({
    callKey: callkey,
    endpoint: `/sub_income_types/${id}`,
    method: "DELETE",
    onSuccess: () => deleteSubIncomeType(id),
  });
